// Should map directly to breakpoints defined in :-
// styles/settints/settings-media.scss
const breakpoints = {
    'small': 480,
    'medium': 768,
    'large': 1024,
    'xlarge': 1280
};

// Return the value
export default breakpoints;
