import BaseComponent from 'components/base-component';
import $ from 'jquery';
import 'magnific-popup';
import lightboxUtils from 'modules/lightbox-utils';
import LoadingSpinner from 'modules/loading-spinner';
import globalEmitter from 'modules/global-emitter';
import Utils from 'modules/utils';
import APIProxy from 'modules/api-proxy';

class DeleteMembershipComponent extends BaseComponent {
    constructor() {
        super();

        this.defaultOptions = {

            selectors: {
                lightboxConfirmRemoveConfirmButton: '[data-confirm-remove]',
            },
            lightboxConfirmSrcName: 'membershipconfirmdelete',
            idAttr: 'data-association-id',
            assocDescAttr:'data-association-description',
        };
    }

    initChildren() {
        this.guid = Utils.generateGUID();
        this.id = this.$el.attr(this.options.idAttr);
        this.assocDesc = this.$el.attr(this.options.assocDescAttr);

        this.lightboxSrcHtml = lightboxUtils.getLightboxSources();
        this.loadingSpinner = new LoadingSpinner();
    }

    addListeners() {
        this.$el.on('click', this._confirmAndDelete.bind(this));
    }

    _confirmAndDelete(e) {
        e.preventDefault();

        const self = this;

        $.magnificPopup.instance.close();

        this.$el.magnificPopup({
            items: {
                src: lightboxUtils.getLightboxMarkupForContent(this.lightboxSrcHtml[this.options.lightboxConfirmSrcName]).replace('{AssociationDescription}', this.assocDesc.trim()),
                type: 'inline',
            },
            mainClass: this.options.modalAdditionalClass,
            callbacks: {
                open: function() {
                    setTimeout(() => {
                        self.$el.off('click.magnificPopup');

                        lightboxUtils.bindOpenModalButtons();

                        self._onConfirmModalOpened($(this.content[0]));
                    }, 0);
                },
            },
        }).magnificPopup('open');
    }

    _onConfirmModalOpened($modalContent) {
        lightboxUtils.bindOpenModalButtons();

        $modalContent.find(this.options.selectors.lightboxConfirmRemoveConfirmButton).on('click', this._onConfirmDeleteClick.bind(this));
    }

    _onConfirmDeleteClick(e) {
        e.preventDefault();

        $.magnificPopup.instance.close();

        this._delete();
    }

    _delete() {
        this.loadingSpinner.request(`${this.guid}-_deleteItem`);

        const self = this;

        APIProxy.request({
            api: 'deleteMembership',
            queryData: {
                Association: this.id,
            },
            success: (data) => {
                $.magnificPopup.instance.close();

                globalEmitter.emit('deletemembership:deleted', self);

                this.loadingSpinner.release(`${this.guid}-_deleteItem`);
            },
            error: (jqxhr, status, err) => {
                this.loadingSpinner.release(`${this.guid}-_deleteItem`);

                let responseStatus = '(no response JSON found; cannot display error details)';

                if (jqxhr.hasOwnProperty('responseJSON')) {
                    responseStatus = jqxhr.responseJSON.Status;
                }
            },
        });
    }

    setData(data) {
        this.data = data;
    }
}

export default () => {
    return new DeleteMembershipComponent();
};
