﻿import BaseComponent from 'components/base-component';
import $ from 'jquery';
import 'magnific-popup';
import lightboxUtils from 'modules/lightbox-utils';
import LoadingSpinner from 'modules/loading-spinner';
import globalEmitter from 'modules/global-emitter';
import Utils from 'modules/utils';
import APIProxy from 'modules/api-proxy';

class DeleteEnrollmentComponent extends BaseComponent {
    constructor() {
        super();

        this.defaultOptions = {
            confirmMessage: {
                deleteRecord: { message: 'Are you sure you want to delete a record from this list?' },
                deleteAll: { message: 'Are you sure you want to delete all the records from the list?' },
            },
            selectors: {
                lightboxConfirmRemoveConfirmButton: '[data-confirm-remove]',
                lightboxConfirmText: '[data-lightbox-text]',
            },
            lightboxConfirmSrcName: 'enrollmentconfirmdelete',
            modalInnerClass: 'e-modal__content',
            idAttr: 'data-id',
        };
    }

    initChildren() {
        this.guid = Utils.generateGUID();
        this.idAttr = this.$el.attr(this.options.idAttr);
        this.lightboxSrcHtml = lightboxUtils.getLightboxSources();
        this.loadingSpinner = new LoadingSpinner();
        this.$deleteRecordMessage = this.options.confirmMessage.deleteRecord.message;
        this.$deleteAllMessage = this.options.confirmMessage.deleteAll.message;

    }

    addListeners() {
        this.$el.on('click', this._confirmAndDelete.bind(this));
    }

    _confirmAndDelete(e) {
        e.preventDefault();
        const self = this;

        $.magnificPopup.instance.close();

        this.$el.magnificPopup({
            items: {
                src: lightboxUtils.getLightboxMarkupForContent(this.lightboxSrcHtml[this.options.lightboxConfirmSrcName]),
                type: 'inline',
            },
            mainClass: this.options.modalAdditionalClass,
            callbacks: {
                open: function () {
                    setTimeout(() => {
                        self.$el.off('click.magnificPopup');

                        lightboxUtils.bindOpenModalButtons();

                        self._onConfirmModalOpened($(this.content[0]));
                    }, 0);
                },
            },
        }).magnificPopup('open');
    }

    _onConfirmModalOpened($modalContent) {
        lightboxUtils.bindOpenModalButtons();

        $modalContent.find(this.options.selectors.lightboxConfirmRemoveConfirmButton).on('click', this._onConfirmDeleteClick.bind(this));
        const $modalContentInner = $modalContent.find(`.${this.options.modalInnerClass}`);

        if (typeof this.idAttr !== "undefined") {
            $modalContentInner.find(this.options.selectors.lightboxConfirmText).text(this.$deleteRecordMessage);
            return;
        }
        else if (typeof this.idAttr === 'undefined') {
            $modalContentInner.find(this.options.selectors.lightboxConfirmText).text(this.$deleteAllMessage);

        }
    }

    _onConfirmDeleteClick(e) {
        e.preventDefault();

        $.magnificPopup.instance.close();

        this._delete();
    }

    _delete() {
        this.loadingSpinner.request(`${this.guid}-_delete`);

        const self = this;
        APIProxy.request({
            api: 'deleteEnrollment',
            queryData: {
                id: this.idAttr,
            },
            success: (data) => {
                $.magnificPopup.instance.close();

                globalEmitter.emit('deleteenrollment:deleted', self);

                this.loadingSpinner.release(`${this.guid}-_delete`);
            },
            error: (jqxhr, status, err) => {
                this.loadingSpinner.release(`${this.guid}-_delete`);

                let responseStatus = '(no response JSON found; cannot display error details)';

                if (jqxhr.hasOwnProperty('responseJSON')) {
                    responseStatus = jqxhr.responseJSON.Status;
                }

                $.magnificPopup.instance.close();

                this.$el.magnificPopup({
                    items: {
                        src: lightboxUtils.getErrorContent('enrollment', 'delete', `${status} ${responseStatus}`, err),
                        type: 'inline',
                    },
                    callbacks: {
                        open: function () {
                            setTimeout(() => {
                                self.$el.off('click.magnificPopup');

                                lightboxUtils.bindOpenModalButtons();
                            }, 0);
                        },
                    },
                    mainClass: this.options.modalAdditionalClass,
                }).magnificPopup('open');

            },
        });
    }

    setData(data) {
        this.data = data;
    }
}

export default () => {
    return new DeleteEnrollmentComponent();
};
