// Polyfills for es6 features we want to use
import 'core-js/es6/promise';
import 'core-js/fn/object/assign';
import 'whatwg-fetch';
import 'babel-polyfill';

// Other polyfills
import 'match-media';
import 'match-media/matchMedia.addListener';
import 'picturefill';

import windowEventProxy from 'modules/window-event-proxy';
import userAgentDetection from 'modules/user-agent-detection';
import jitRequire from 'modules/jit-require';
import './lib/xhr';

jitRequire(document);
windowEventProxy.init();
userAgentDetection.init();